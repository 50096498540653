// src/components/Contact.js
import React from 'react';
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';

const Contact = () => {
  return (
    <section className="py-16 bg-gray-200 text-gray-800">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold mb-8">Contact Us</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="bg-white p-6 shadow-md hover:shadow-lg transition-shadow duration-300 flex justify-center items-center">
            <div className="flex items-center space-x-2">
              <FaPhoneAlt className="text-blue-500" />
              <p>+2349094859317</p>
            </div>
          </div>
          <div className="bg-white p-6 shadow-md hover:shadow-lg transition-shadow duration-300 flex justify-center items-center">
            <div className="flex items-center space-x-2">
              <FaEnvelope className="text-blue-500" />
              <p>info@canflycaptain.com</p>
            </div>
          </div>
          <div className="bg-white p-6 shadow-md hover:shadow-lg transition-shadow duration-300 flex justify-center items-center">
            <div className="flex items-center space-x-2">
              <FaMapMarkerAlt className="text-blue-500" />
              <p>Suite 213 Jinifa Plaza Central Business District Abuja</p>
            </div>
          </div>
        </div>
        <div className="mt-8 mx-auto w-full md:w-3/4 lg:w-1/2 p-4">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3940.092953243215!2d7.476471874846153!3d9.055285691007086!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104e0b09acbafc63%3A0xd9b43ebfc25ca202!2sJinifa%20Plaza!5e0!3m2!1sen!2sng!4v1721133591649!5m2!1sen!2sng"
            width="100%"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title='Contact'
          ></iframe>
        </div>
      </div>
    </section>
  );
};

export default Contact;
