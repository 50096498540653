// src/pages/CourseDetailsPage.js

import React, { useState, useEffect } from 'react';
import PaystackButton from '../components/PaystackButton';
import Countdown from 'react-countdown';
import { motion } from 'framer-motion';
import backgroundImage from '../assets/Img/hero5.jpeg';
import { sendEmail } from '../utils/emailUtils';

const CourseDetailsPage = () => {
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [offerActive, setOfferActive] = useState(true);
  const [discountedPrice, setDiscountedPrice] = useState(15000); // 90% discount on NGN 150,000
  const [formData, setFormData] = useState({ firstName: '', lastName: '', email: '' });
  const [formValid, setFormValid] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (offerActive) {
        setOfferActive(false);
        setTimeout(() => {
          setDiscountedPrice(150000); // Reset to original price after additional 10 minutes
        }, 600000); // Additional 10 minutes in milliseconds
      }
    }, 1200000); // 20 minutes in milliseconds

    return () => clearTimeout(timer);
  }, [offerActive]);

  useEffect(() => {
    const { firstName, lastName, email } = formData;
    setFormValid(firstName.trim() !== '' && lastName.trim() !== '' && email.trim() !== '');
  }, [formData]);

  const handlePaymentSuccess = (reference) => {
    console.log(reference);
    setPaymentSuccess(true);
    const uniqueId = `SH${Date.now()}`;
    sendEmail({
      to_email: formData.email,
      first_name: formData.firstName,
      last_name: formData.lastName,
      unique_id: uniqueId,
      course_title: 'Transform into a Digital Marketing Travel Expert: Master Visa Processing, Migration, and Travel Bookings to Earn $1000 - $5000 Monthly',
      course_summary: `
        Unlock your potential in the travel industry with our Digital Marketing Travel Expert course. This program is designed to equip you with essential skills in visa processing, migration, and travel arrangements, all while mastering digital marketing strategies tailored to the African market. Earn $1000 - $5000 monthly by transforming leads into loyal customers and managing seamless travel experiences. Gain hands-on experience through practical projects and earn an industry-recognized certification, setting you on the path to becoming a sought-after travel expert. Join us to achieve financial success and professional growth in the dynamic travel industry.
      `,
      price: discountedPrice,
    });
    window.location.href = 'https://wa.me/2349096786796'; // Redirect to WhatsApp
  };

  const handlePaymentClose = () => {
    console.log('Payment closed');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Animation Variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { staggerChildren: 0.3 } }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: { opacity: 1, y: 0, transition: { type: 'spring', stiffness: 120 } }
  };

  return (
    <div
      className="relative min-h-screen flex items-center justify-center bg-cover bg-center"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>
      <motion.div 
        className="relative z-10 bg-white bg-opacity-50 p-8 rounded shadow-lg w-full md:w-4/5 lg:w-3/5"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <motion.h1 variants={itemVariants} className="text-2xl md:text-4xl font-bold mb-4">
          Transform into a Digital Marketing Travel Expert: Master Visa Processing, Migration, and Travel Bookings to Earn <span className="text-green-500">$1000 - $5000</span> Monthly
        </motion.h1>
        <motion.div variants={itemVariants} className="text-lg mb-4">
          <h2 className="text-xl font-bold mb-2">Course Objectives:</h2>
          <ul className="list-disc pl-5 space-y-2">
            <li>Unlock Lucrative Opportunities: Equip yourself with the essential skills to dominate the travel industry and achieve a sustainable income of $1000 - $5000 monthly.</li>
            <li>Master Visa Processing: Gain in-depth knowledge and expertise in navigating visa applications, ensuring smooth and successful approvals for clients.</li>
            <li>Expertise in Migration and Dual Citizenship: Become a go-to advisor on migration laws and dual citizenship benefits, guiding clients through seamless transitions.</li>
            <li>Seamless Travel Arrangements: Learn to plan, arrange, and book tours and flights with precision, ensuring clients have unforgettable travel experiences.</li>
            <li>Digital Marketing Proficiency: Develop cutting-edge digital marketing strategies tailored to the African market, capturing and converting potential clients effectively.</li>
            <li>Lead Conversion Mastery: Transform leads into loyal customers using proven sales techniques, and build a robust client base.</li>
            <li>Financial Success and Management: Acquire financial acumen to manage earnings, set realistic financial goals, and ensure legal compliance as a travel expert.</li>
            <li>Hands-On Experience: Engage in practical projects and case studies, gaining real-world experience that sets you apart in the competitive travel industry.</li>
            <li>Industry-Recognized Certification: Earn a prestigious certification as a Digital Marketing Travel Expert, showcasing your expertise to clients and employers.</li>
            <li>Long-Term Growth: Access continuous learning opportunities and stay ahead of industry trends, ensuring long-term success and growth in your travel career.</li>
          </ul>
        </motion.div>
        <motion.div variants={itemVariants} className="text-lg mb-4">
          <h2 className="text-xl font-bold mb-2">Course Summary:</h2>
          <p>Are you ready to transform your passion for travel into a profitable career? Our comprehensive course, Transform into a Digital Marketing Travel Expert, is designed to equip you with the essential skills and knowledge to thrive in the travel industry. From mastering visa processing and migration to arranging seamless tours and flights, you’ll learn everything you need to know to provide top-notch services to your clients.</p>
          <p>But that's not all—this course places a strong emphasis on digital marketing, empowering you to capture and convert a market within Africa effectively. You'll develop strategies to attract and retain clients, ensuring you achieve a steady income of $1000 - $5000 monthly.</p>
          <p>With hands-on projects, expert-led workshops, and real-world case studies, you'll gain practical experience that sets you apart in this competitive field. Upon completion, you'll earn an industry-recognized certification as a Digital Marketing Travel Expert, opening doors to endless opportunities.</p>
          <p>Join us and embark on a journey to financial success and professional growth in the dynamic world of travel.</p>
        </motion.div>
        <motion.p variants={itemVariants} className="text-xl font-bold mb-4">
          Original Price: <span className="line-through">NGN 150,000</span> <span className={offerActive ? "text-red-600" : ""}>Current Price: NGN {discountedPrice}</span>
        </motion.p>
        {offerActive && (
          <motion.div variants={itemVariants} className="mb-4">
            <p className="text-red-600 font-bold">Limited Time Offer! 90% Discount for the next:</p>
            <Countdown date={Date.now() + 1200000} />
          </motion.div>
        )}
        <motion.div variants={itemVariants} className="mb-4">
          <input
            type="text"
            name="firstName"
            placeholder="First name"
            value={formData.firstName}
            onChange={handleChange}
            className="w-full p-2 mb-2 rounded border"
            required
          />
          <input
            type="text"
            name="lastName"
            placeholder="Last name"
            value={formData.lastName}
            onChange={handleChange}
            className="w-full p-2 mb-2 rounded border"
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            className="w-full p-2 mb-2 rounded border"
            required
          />
        </motion.div>
        {!paymentSuccess ? (
          <PaystackButton
            email={formData.email}
            amount={discountedPrice} // Replace with actual course amount
            onSuccess={handlePaymentSuccess}
            onClose={handlePaymentClose}
            disabled={!formValid} // Disable button if form is not valid
          />
        ) : (
          <motion.p variants={itemVariants} className="text-green-500">Payment successful! You are now enrolled.</motion.p>
        )}
      </motion.div>
    </div>

  );
};

export default CourseDetailsPage;
