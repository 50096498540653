// src/components/Testimonials.js
import React from 'react';

const testimonials = [
  { name: 'John Doe', testimonial: 'Amazing travel services! Highly recommended.' },
  { name: 'Jane Smith', testimonial: 'A wonderful experience from start to finish.' }
];

const Testimonials = () => {
  return (
    <section className="py-16 bg-white text-gray-800">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold mb-8">What Our Clients Say</h2>
        <div className="space-y-8">
          {testimonials.map((testimonial, index) => (
            <div key={index} className="p-6 border rounded-lg">
              <p className="italic">"{testimonial.testimonial}"</p>
              <p className="mt-4 font-semibold">- {testimonial.name}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
