import React, { useEffect } from 'react';
import plane from '../assets/Img/Plane.png';
import '../styles/loading.css';

const Loading = ({ onComplete }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onComplete();
    }, 3000); // Duration of the animation

    return () => clearTimeout(timer);
  }, [onComplete]);

  return (
    <div className="loading-container">
      <img src={plane} alt="Plane" className="plane" />
    </div>
  );
};

export default Loading;
