// src/components/FlightForm.js
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const countries = ["USA", "UK", "Canada", "Australia", "Germany"];

const FlightForm = () => {
  const [currentCountry, setCurrentCountry] = useState(countries[0]);
  const [showModal, setShowModal] = useState(false);
  const [formStep, setFormStep] = useState(1); // State to track which form step is displayed

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentCountry((prevCountry) => {
        const currentIndex = countries.indexOf(prevCountry);
        const nextIndex = (currentIndex + 1) % countries.length;
        return countries[nextIndex];
      });
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  const handleClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setFormStep(1); // Reset the form step when the modal is closed
  };

  const handleNextStep = () => {
    setFormStep(2);
  };

  const handlePreviousStep = () => {
    setFormStep(1);
  };

  return (
    <>
      <motion.div
        className="w-full md:w-4/5 mx-auto p-4 bg-white shadow-lg rounded-lg cursor-pointer"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, ease: "easeInOut" }}
        onClick={handleClick}
      >
        <h1 className="text-3xl md:text-5xl font-bold text-center">
          Want To Study In <span className="text-gold">{currentCountry}</span>
        </h1>
      </motion.div>

      {/* Modal for the student application form */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded-lg shadow-lg w-11/12 md:w-2/3 lg:w-1/2">
            <h2 className="text-xl font-bold mb-4">Student Application Form</h2>
            <form>
              {formStep === 1 && (
                <motion.div
                  key="step1"
                  initial={{ opacity: 0, x: -100 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: 100 }}
                >
                  {/* First part of the form */}
                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Full Name
                    </label>
                    <input
                      type="text"
                      placeholder="Enter your full name"
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Email Address
                    </label>
                    <input
                      type="email"
                      placeholder="Enter your email address"
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                  </div>
                  <div className="flex justify-end">
                    <button
                      type="button"
                      className="bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-700"
                      onClick={handleNextStep}
                    >
                      Next
                    </button>
                  </div>
                </motion.div>
              )}

              {formStep === 2 && (
                <motion.div
                  key="step2"
                  initial={{ opacity: 0, x: 100 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -100 }}
                >
                  {/* Second part of the form */}
                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      WhatsApp Number
                    </label>
                    <input
                      type="tel"
                      placeholder="Enter your WhatsApp number"
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Highest Level of Education
                    </label>
                    <select
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    >
                      <option value="">Select your education level</option>
                      <option value="highschool">High School</option>
                      <option value="bachelor">Bachelor's Degree</option>
                      <option value="masters">Master's Degree</option>
                      <option value="phd">PhD</option>
                    </select>
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Nationality
                    </label>
                    <input
                      type="text"
                      placeholder="Enter your nationality"
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                  </div>
                  <div className="flex justify-between">
                    <button
                      type="button"
                      className="bg-gray-500 text-white font-bold py-2 px-4 rounded hover:bg-gray-700"
                      onClick={handlePreviousStep}
                    >
                      Previous
                    </button>
                    <button
                      type="submit"
                      onClick={handleCloseModal}
                      className="bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-700"
                    >
                      Submit
                    </button>
                  </div>
                </motion.div>
              )}
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default FlightForm;
