// src/pages/HomePage.js
import React from 'react';
import Hero from '../components/Hero';
import Contact from '../components/Contact';
import Testimonials from '../components/Testimonials';
import Newsletter from '../components/Newsletter';
import AnimatedSection from '../components/AnimatedSection';
import Services from '../components/Services';

const HomePage = () => {
  return (
    <div>
      <Hero />
      <AnimatedSection>
        <Services />
      </AnimatedSection>
      <AnimatedSection>
        <Contact />
      </AnimatedSection>
      <AnimatedSection>
        <Testimonials />
      </AnimatedSection>
      <AnimatedSection>
        <Newsletter />
      </AnimatedSection>
    </div>
  );
};

export default HomePage;
