// src/components/PaystackButton.js
import React from 'react';
import { usePaystackPayment } from 'react-paystack';

const PaystackButton = ({ email, amount, onSuccess, onClose }) => {
  const config = {
    reference: new Date().getTime().toString(),
    email: email,
    amount: amount * 100, // Convert amount to kobo
    publicKey: 'pk_live_9ba5cf68e2b34186ff85ec301b11d840ffef7b67', // Replace with your Paystack public key
  };

  const initializePayment = usePaystackPayment(config);

  return (
    <button
      onClick={() => {
        initializePayment(onSuccess, onClose);
      }}
      className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700 transition-colors"
    >
      Enroll Now
    </button>
  );
};

export default PaystackButton;
