// src/utils/emailUtils.js
import emailjs from 'emailjs-com';

export const sendEmail = (templateParams) => {
  return emailjs.send(
    'service_xhxrknp',
    'template_b8la13h',
    templateParams,
    'HdXLpgd5Un-wRvaKL'
  );
};
