// src/pages/CourseSummaryPage.js

import React, { useState, useEffect } from 'react';
import PaystackButton from '../components/PaystackButton';
import Countdown from 'react-countdown';
import { motion } from 'framer-motion';
import backgroundImage from '../assets/Img/hero5.jpeg';
import { sendEmail } from '../utils/emailUtils';

const CourseSummaryPage = () => {
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [offerActive, setOfferActive] = useState(true);
  const [discountedPrice, setDiscountedPrice] = useState(15000); // 90% discount on NGN 150,000
  const [formData, setFormData] = useState({ firstName: '', lastName: '', email: '' });
  const [formValid, setFormValid] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (offerActive) {
        setOfferActive(false);
        setTimeout(() => {
          setDiscountedPrice(150000); // Reset to original price after additional 10 minutes
        }, 600000); // Additional 10 minutes in milliseconds
      }
    }, 1200000); // 20 minutes in milliseconds

    return () => clearTimeout(timer);
  }, [offerActive]);

  useEffect(() => {
    const { firstName, lastName, email } = formData;
    setFormValid(firstName.trim() !== '' && lastName.trim() !== '' && email.trim() !== '');
  }, [formData]);

  const handlePaymentSuccess = (reference) => {
    console.log(reference);
    setPaymentSuccess(true);
    const uniqueId = `SH${Date.now()}`;
    sendEmail({
      to_email: formData.email,
      first_name: formData.firstName,
      last_name: formData.lastName,
      unique_id: uniqueId,
      course_title: 'Transform into a Digital Marketing Travel Expert: Master Visa Processing, Migration, and Travel Bookings to Earn $1000 - $5000 Monthly',
      course_summary: `
        Unlock your potential in the travel industry with our Digital Marketing Travel Expert course. This program is designed to equip you with essential skills in visa processing, migration, and travel arrangements, all while mastering digital marketing strategies tailored to the African market. Earn $1000 - $5000 monthly by transforming leads into loyal customers and managing seamless travel experiences. Gain hands-on experience through practical projects and earn an industry-recognized certification, setting you on the path to becoming a sought-after travel expert. Join us to achieve financial success and professional growth in the dynamic travel industry.
      `,
      price: discountedPrice,
    });
    window.location.href = 'https://wa.me/2349096786796'; // Redirect to WhatsApp
  };

  const handlePaymentClose = () => {
    console.log('Payment closed');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Animation Variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { staggerChildren: 0.3 } }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: { opacity: 1, y: 0, transition: { type: 'spring', stiffness: 120 } }
  };

  return (
    <div
      className="relative min-h-screen flex items-center justify-center bg-cover bg-center"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>
      <motion.div 
        className="relative z-10 bg-white bg-opacity-50 p-8 rounded shadow-lg w-full md:w-4/5 lg:w-3/5"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <motion.h1 variants={itemVariants} className="text-2xl md:text-4xl font-bold mb-4">
          Transform into a Digital Marketing Travel Expert: Master Visa Processing, Migration, and Travel Bookings to Earn <span className="text-green-500">$1000 - $5000</span> Monthly
        </motion.h1>
        <motion.p variants={itemVariants} className="text-lg mb-4">
          Unlock your potential in the travel industry with our Digital Marketing Travel Expert course. This program is designed to equip you with essential skills in visa processing, migration, and travel arrangements, all while mastering digital marketing strategies tailored to the African market. Earn $1000 - $5000 monthly by transforming leads into loyal customers and managing seamless travel experiences. Gain hands-on experience through practical projects and earn an industry-recognized certification, setting you on the path to becoming a sought-after travel expert. Join us to achieve financial success and professional growth in the dynamic travel industry.
        </motion.p>
        <motion.p variants={itemVariants} className="text-xl font-bold mb-4">
          Original Price: <span className="line-through">NGN 150,000</span> <span className={offerActive ? "text-red-600" : ""}>Current Price: NGN {discountedPrice}</span>
        </motion.p>
        {offerActive && (
          <motion.div variants={itemVariants} className="mb-4">
            <p className="text-red-600 font-bold">Limited Time Offer! 90% Discount for the next:</p>
            <Countdown date={Date.now() + 1200000} />
          </motion.div>
        )}
        <motion.div variants={itemVariants} className="mb-4">
          <input
            type="text"
            name="firstName"
            placeholder="First name"
            value={formData.firstName}
            onChange={handleChange}
            className="w-full p-2 mb-2 rounded border"
            required
          />
          <input
            type="text"
            name="lastName"
            placeholder="Last name"
            value={formData.lastName}
            onChange={handleChange}
            className="w-full p-2 mb-2 rounded border"
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            className="w-full p-2 mb-2 rounded border"
            required
          />
        </motion.div>
        {!paymentSuccess ? (
          <PaystackButton
            email={formData.email}
            amount={discountedPrice} // Replace with actual course amount
            onSuccess={handlePaymentSuccess}
            onClose={handlePaymentClose}
            disabled={!formValid} // Disable button if form is not valid
          />
        ) : (
          <motion.p variants={itemVariants} className="text-green-500">Payment successful! You are now enrolled.</motion.p>
        )}
      </motion.div>
    </div>
  );
};

export default CourseSummaryPage;
