import React from 'react';

const DestinationCard = ({ destination, onSeeDetails }) => {
  return (
    <div
  className="relative destination-card bg-cover bg-center rounded-lg shadow-lg flex flex-col justify-end w-[50vw] md:w-[20vw] h-[50vw] md:h-[20vw]"
  style={{ backgroundImage: `url(${destination.image})`, paddingBottom: '1rem' }}
>
  <div className="bg-black bg-opacity-50 p-4 rounded-b-lg text-white">
    <h3 className="text-lg font-bold">{destination.location}</h3>
    <button
      className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition-colors"
      onClick={() => onSeeDetails(destination)}
    >
      See Details
    </button>
  </div>
</div>
  );
};

export default DestinationCard;
