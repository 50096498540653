import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import HomePage from './pages/HomePage';
import Blog from './pages/BlogPage';
import CourseSummaryPage from './pages/CourseSummaryPage';
import CourseDetailsPage from './pages/CourseDetailsPage';
import StudyAboard from './components/StudyAboard';
import Loading from './components/Loading';
import './styles/loading.css';

const App = () => {
  const [loading, setLoading] = useState(true);

  const handleLoadingComplete = () => {
    setLoading(false);

    // Show poster after 1 minute
    const timer = setTimeout(() => {
    }, 60000);

    return () => clearTimeout(timer);
  };

  useEffect(() => {
    if (!loading) {
      // Show poster after 1 minute when loading is complete
      const timer = setTimeout(() => {
      }, 60000);

      return () => clearTimeout(timer);
    }
  }, [loading]);
  

  return (
    <Router>
      {loading && <Loading onComplete={handleLoadingComplete} />}
      {!loading && (
        <Layout>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/course-summary" element={<CourseSummaryPage />} />
            <Route path="/course-details" element={<CourseDetailsPage />} />
            <Route path="/study" element={<StudyAboard />} />
          </Routes>
        </Layout>
      )}
    </Router>
  );
};

export default App;
