// src/components/DestinationList.js
import hero1 from '../assets/Img/hero1.jpg';
import hero2 from '../assets/Img/hero2.jpg';
import hero3 from '../assets/Img/hero3.jpg';
import hero4 from '../assets/Img/hero4.jpg';

const destinations = [
  {
    image: hero1,
    location: "Paris",
    details: "Paris is the capital city of France, known for its art, fashion, and culture...",
    monumentalToursPrice: 100,
    visaApplicationPrice: 200,
    hotelBookingPrice: 300,
    carRentalPrice: 50,
    shoppingPickupPrice: 30,
    leisureHangoutPrice: 40,
    companyVisitPrice: 60,
  },
  {
    image: hero2,
    location: "New York",
    details: "New York is known for its iconic landmarks like Times Square and Central Park...",
    monumentalToursPrice: 100,
    visaApplicationPrice: 200,
    hotelBookingPrice: 300,
    carRentalPrice: 50,
    shoppingPickupPrice: 30,
    leisureHangoutPrice: 40,
    companyVisitPrice: 60,
  },
  {
    image: hero3,
    location: "Tokyo",
    details: "Tokyo is the capital city of Japan, known for its modernity and traditional culture...",
    monumentalToursPrice: 100,
    visaApplicationPrice: 200,
    hotelBookingPrice: 300,
    carRentalPrice: 50,
    shoppingPickupPrice: 30,
    leisureHangoutPrice: 40,
    companyVisitPrice: 60,
  },
  {
    image: hero4,
    location: "London",
    details: "London is the capital of England, known for its rich history and landmarks like the Big Ben...",
    monumentalToursPrice: 100,
    visaApplicationPrice: 200,
    hotelBookingPrice: 300,
    carRentalPrice: 50,
    shoppingPickupPrice: 30,
    leisureHangoutPrice: 40,
    companyVisitPrice: 60,
  },
];

export default destinations;
