// src/components/HeroSection.js
import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { motion } from 'framer-motion';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import hero1 from '../assets/Img/hero1.jpg';
import hero2 from '../assets/Img/hero2.jpg';
import hero3 from '../assets/Img/hero3.jpg';
import hero4 from '../assets/Img/hero4.jpg';
import hero5 from '../assets/Img/hero5.jpeg';
import DestinationCard from './DestinationCard';
import destinations from './DestinationList';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const HeroSection = () => {
  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 20000,
    fade: true,
    cssEase: 'linear',
  };

  const cardSliderSettings = {
    leftMode: true,
    Padding: '1',
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1.5,
        },
      },
    ],
  };

  const images = [hero1, hero2, hero3, hero4, hero5];
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedDestination, setSelectedDestination] = useState(null);

  const handleSeeDetails = (destination) => {
    setSelectedDestination(destination);
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setSelectedDestination(null);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <div className="w-full relative overflow-hidden md:h-screen h-1/2">
        <Slider {...sliderSettings} className="absolute top-0 left-0 w-full h-full z-10">
          {images.map((image, index) => (
            <div key={index} className="w-full h-full">
              <img
                src={image}
                alt={`Hero ${index + 1}`}
                className="w-full h-full object-cover"
              />
              <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-40 z-20"></div>
            </div>
          ))}
        </Slider>
        <motion.div
          className="absolute top-0 left-0 w-full h-full z-30 flex flex-col md:flex-row items-center justify-between p-8"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <div className="text-white w-full md:w-1/2 text-center md:text-left">
            <motion.h1
              className="text-lg md:text-4xl lg:text-5xl mt-4 md:mt-0"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.5 }}
            >
              Your Trusted Travel Partner
            </motion.h1>
            <motion.p
              className="mt-2 text-xs md:text-lg lg:text-2xl"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 1 }}
            >
              Flight bookings, Visa assistance, Hotel reservations, and more!
            </motion.p>
          </div>
          {!isSmallScreen && (
            <motion.div
              className="w-full md:w-1/2 mt-4 md:mt-0"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 1.5 }}
            >
              <Slider {...cardSliderSettings} className="relative bg-white bg-opacity-30 p-4 rounded-md z-40">
                {destinations.map((dest, index) => (
                  <DestinationCard
                    key={index}
                    destination={dest}
                    onSeeDetails={() => handleSeeDetails(dest)}
                  />
                ))}
              </Slider>
            </motion.div>
          )}
        </motion.div>
      </div>
      {isSmallScreen && (
        <div className="relative z-40 -mt-20">
          <Slider {...cardSliderSettings} className="relative bg-white bg-opacity-70 p-4 rounded-md">
            {destinations.map((dest, index) => (
              <DestinationCard
                key={index}
                destination={dest}
                onSeeDetails={() => handleSeeDetails(dest)}
              />
            ))}
          </Slider>
        </div>
      )}

      {showPopup && selectedDestination && (
        <div className="fixed inset-0 bg-black bg-opacity-75 z-50 flex justify-center items-center">
          <div className="bg-white p-4 rounded-lg shadow-lg w-11/12 md:w-1/2 relative">
            <h2 className="text-2xl font-bold mb-4">Explore {selectedDestination.location}</h2>
            <img
              src={selectedDestination.image}
              alt={selectedDestination.location}
              className="w-full h-64 object-cover rounded-lg mb-4"
            />
            <p>{selectedDestination.details}</p>
            <button
              className="mt-4 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700 transition-colors"
              onClick={handleClosePopup}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default HeroSection;
