// src/components/Newsletter.js
import React from 'react';

const Newsletter = () => {
  return (
    <section className="py-16 bg-gray-200 text-gray-800">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold mb-8">Stay Updated</h2>
        <form className="space-y-4">
          <input type="email" placeholder="Enter your email" className="p-3 w-full border rounded-lg" />
          <button type="submit" className="px-4 py-2 bg-red-500 hover:bg-red-600 text-white rounded-lg">Subscribe</button>
        </form>
      </div>
    </section>
  );
};

export default Newsletter;
